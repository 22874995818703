import React, { createContext, useState } from "react";
import { FullLayout, VerticalLayout, HorizontalLayout } from "app/layouts";

const ContextLayout = createContext();

function Layout({ children }) {
  const [activeLayout, setActiveLayout] = useState("vertical");

  return (
    <ContextLayout.Provider
      value={{
        activeLayout,
        fullLayout: FullLayout,
        verticalLayout: VerticalLayout,
        horizontalLayout: HorizontalLayout,
        switchLayout: (layout) => {
          setActiveLayout(layout);
        },
      }}
    >
      {children}
    </ContextLayout.Provider>
  );
}

export { Layout, ContextLayout };
