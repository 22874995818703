import { combineReducers } from "redux";
import uiReducer from "./ui";
import entitiesReducer from "./entities";
import authReducer from "../slices/auth";
import loaderReducer from "../slices/loader";

export default combineReducers({
  ui: uiReducer,
  entities: entitiesReducer,
  auth: authReducer,
  loader: loaderReducer,
});
