import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { X, ChevronsLeft, ChevronsRight } from "react-feather";
import classnames from "classnames";
import userImg from "assets/img/avatar.png";
import { connect } from "react-redux";

class SidebarHeader extends Component {
  render() {
    //console.log(this.props.currentUser);
    let {
      toggleSidebarMenu,
      activeTheme,
      collapsed,
      toggle,
      sidebarVisibility,
      menuShadow,
      currentUser,
    } = this.props;
    return (
      <div
        className="navbar-header mt-4 mb-4"
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img
          style={{ borderRadius: "50%" }}
          src={currentUser.avatar}
          className="round"
          height="100"
          width="100"
          alt="avatar"
        />
        <h6 className="mt-1 mb-0" style={{ fontWeight: "500" }}>
          {currentUser.username}
        </h6>
        <span style={{ fontWeight: "600" }}>{currentUser.school_name}</span>

        <div
          className={classnames("shadow-bottom", {
            "d-none": menuShadow === false,
          })}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { currentUser: state.auth.currentUser };
}

export default connect(mapStateToProps)(SidebarHeader);
