import { createSlice } from "@reduxjs/toolkit";
//import jwt_decode from "jwt-decode";
import { apiCallBegan } from "app/store";
import setAuthToken from "@core/helpers/auth/setAuthToken";

const slice = createSlice({
  name: "auth",
  initialState: {
    currentUser: {},
    isAuthenticated: false,
    loading: false,
    //token: localStorage.getItem("token"),
    error: false,
  },
  reducers: {
    authRequested: (auth, action) => {
      auth.loading = true;
    },
    authRequestFailed: (auth, action) => {
      auth.isAuthenticated = false;
      auth.loading = false;
      auth.error = true;
      //console.log(action.payload);
    },
    authTokenReceived: (auth, action) => {
      let token = action.payload;
      localStorage.setItem("token", token);
      setAuthToken(token);
      auth.isAuthenticated = true;
      auth.loading = false;
      //auth.token = token;
      window.location = "/";
      //setAuthToken(token);
      //console.log("token : " + action.payload);
    },
    setCurrentUser: (auth, action) => {
      //localStorage.setItem("token", action.payload);
      //console.log(action.payload);
      auth.currentUser = action.payload;
      auth.isAuthenticated = true;
      auth.loading = false;
    },
    authLoggedOut: (auth, action) => {
      localStorage.clear();
      auth.isAuthenticated = false;
      window.location = "/login";
    },
  },
});

export const {
  authRequested,
  authRequestFailed,
  authTokenReceived,
  setCurrentUser,
  authLoggedOut,
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "/auth/login";

export const loginUser = (user) =>
  apiCallBegan({
    url,
    method: "post",
    data: user,
    onStart: authRequested.type,
    onSuccess: authTokenReceived.type,
    onError: authRequestFailed.type,
  });

export const logoutUser = () =>
  apiCallBegan({
    url: "auth/logout",
    //onStart: authRequested.type,
    onSuccess: authLoggedOut.type,
    //onError: authRequestFailed.type,
  });
