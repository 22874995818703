import React, { PureComponent } from "react";
//import classnames from "classnames";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { history } from "app/routes/history";
import * as Icon from "react-feather";
import logo from "assets/img/logo.svg";
import { Link } from "react-router-dom";

const handleNavigation = (e, path) => {
  e.preventDefault();
  history.push(path);
};

const UserDropdown = (props) => {
  return (
    <DropdownMenu right>
      <DropdownItem
        tag="a"
        href="#"
        onClick={(e) => handleNavigation(e, "/pages/profile")}
      >
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Mon profil</span>
      </DropdownItem>
      <DropdownItem
        tag="a"
        href="#"
        onClick={(e) => handleNavigation(e, "/pages/wishlist")}
      >
        <Icon.Heart size={14} className="mr-50" />
        <span className="align-middle">Mes favoris</span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem tag="a" href="/pages/login">
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Déconnexion</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

class NavbarUser extends PureComponent {
  state = {
    navbarSearch: false,
  };

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch,
    });
  };

  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <Link to="/">
          <span data-tour="user">
            <img src={logo} alt="VOSCH Edu" />
          </span>
        </Link>
        {/**
         * <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props.userName}
              </span>
              <span className="user-status">Admin</span>
            </div>
            <Link to="/home">
              <span data-tour="user">
                <img src={logo} alt="VOSCH Edu" />
              </span>
            </Link>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
         */}
      </ul>
    );
  }
}
export default NavbarUser;
