import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import reducer from "./reducer";
// Middlewares
import api from "../middlewares/api";
import toastr from "../middlewares/toastr";

export default function () {
  return configureStore({
    reducer,
    middleware: [...getDefaultMiddleware(), toastr, api],
    //devTools: false,
  });
}
