import { createAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

export const toastrPostSuccess = createAction("toastr/postSuccess");
export const toastrPostError = createAction("toastr/postError");

toast.configure();

const toastr = (store) => (next) => (action) => {
  if (action.type === toastrPostSuccess.type)
    toast.success(`😋 ${action.payload.message}`);
  if (action.type === toastrPostError.type)
    toast.error(`😋 ${action.payload.message}`);
  else next(action);
};

export default toastr;
