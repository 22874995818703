import React from "react";
import classnames from "classnames";
import { Button } from "reactstrap";
import ScrollToTop from "react-scroll-up";
import { Home, Search, Bell, MessageSquare, ArrowUp } from "react-feather";
import icon from "assets/img/send.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const style = {
  marginBottom: "0",
  paddingLeft: "0",
  listStyle: "none",
  display: "flex",
  justifyContent: "space-between",
};

function Footer(props) {
  const currentUser = useSelector((state) => state.auth.currentUser);
  let footerTypeArr = ["sticky", "static", "hidden"];
  return (
    <footer
      className={classnames("footer footer-light", {
        "footer-static":
          props.footerType === "static" ||
          !footerTypeArr.includes(props.footerType),
        "d-none": props.footerType === "hidden",
      })}
    >
      {/**
       * <ul style={style}>
        <li>
          <Home size={30} />
        </li>
        <li>
          <Search size={30} />
        </li>
        <li>
          <Bell size={30} />
        </li>
        <li>
          <MessageSquare size={30} />
        </li>
      </ul>
      
       */}
      {/**
       * <p className="mb-0 clearfix">
        <span className="float-md-left d-block d-md-inline-block mt-25">
          Copyright © {new Date().getFullYear()}
          <a href="https://vosch.co/" target="_blank" rel="noopener noreferrer">
            VOSCH.
          </a>
          Tous droits réservés.
        </span>
        <span className="float-md-right d-none d-md-block">
          
          <span className="align-middle"> VOSCH Edu. | Version bêta.</span>
        </span>
      </p>
      
       */}
      {/**
        * {props.hideScrollToTop === false ? (
        <ScrollToTop showUnder={160}>
          <Button color="primary" className="btn-icon scroll-top">
            <ArrowUp size={15} />
          </Button>
        </ScrollToTop>
      ) : null}
        */}
      {(props.hideScrollToTop === false) & currentUser.can_post ? (
        <Link
          to="/post"
          className="btn-icon scroll-top"
          style={{ borderRadius: "50%", bottom: "5px", right: "5px" }}
        >
          <img src={icon} width="50" />
        </Link>
      ) : null}
    </footer>
  );
}

export default Footer;
