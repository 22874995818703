// configureStore
import configureStore from "./setup/configureStore";

// Middlewares
import { apiCallBegan } from "./middlewares/api";

// Customizer actions
import {
  changeMode,
  collapseSidebar,
  changeNavbarColor,
  changeNavbarType,
  changeFooterType,
  changeMenuColor,
  hideScrollToTop,
} from "./slices/customizer";

// Posts actions
import { addPost } from "./slices/posts";
import { loadPosts } from "./slices/posts";

export default configureStore;
export {
  apiCallBegan,
  changeMode,
  collapseSidebar,
  changeNavbarColor,
  changeNavbarType,
  changeFooterType,
  changeMenuColor,
  hideScrollToTop,
  addPost,
  loadPosts,
};
