import { combineReducers } from "redux";
//import customizerReducer from "../slices/customizer";
import postsReducer from "../slices/posts";
//import commentsReducer from "../slices/comments";

export default combineReducers({
  //customizer: customizerReducer,
  posts: postsReducer,
  //comments: commentsReducer,
  //users: usersReducer,
});
