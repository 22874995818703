import React, { Component } from "react";
import logo from "assets/img/logo.svg";
import {
  FaUserAlt,
  FaLock,
  FaUserFriends,
  FaUserGraduate,
  FaSignOutAlt,
} from "react-icons/fa";
import { connect } from "react-redux";
import { logoutUser } from "app/store/slices/auth";
import { Link } from "react-router-dom";

const sticky = {
  /*
  position: "-webkit-sticky",
  position: "sticky",
  bottom: "1rem",
  */
  display: "flex",
  //flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

const menuTitle = {
  fontSize: "1.5rem",
  fontWeight: "600",
};

const menuSubTitle = {
  fontSize: ".9rem",
  fontWeight: "600",
};

class SidebarContent extends Component {
  render() {
    return (
      <>
        <div className="ml-2">
          <h1 style={menuTitle}>Account</h1>
          <h6 className="mt-2" style={menuSubTitle}>
            Personal info
          </h6>
          <ul className="ml-2 mt-2">
            <li>
              <FaUserAlt size={10} />{" "}
              <span style={{ marginLeft: ".5rem" }}>Your profil</span>
            </li>
            <li>
              <FaLock size={10} />{" "}
              <Link to="/settings/password">
                <span style={{ marginLeft: ".5rem" }}>Change password</span>
              </Link>
            </li>
          </ul>
          <h6 className="mt-2" style={menuSubTitle}>
            Contact
          </h6>
          <ul className="ml-2 mt-2">
            <li>
              <FaUserGraduate size={10} />{" "}
              <span style={{ marginLeft: ".5rem" }}>Your students</span>
            </li>
            <li>
              <FaUserFriends size={10} />{" "}
              <span style={{ marginLeft: ".5rem" }}>Friends</span>
            </li>
          </ul>
          <h6 className="mt-2" style={menuSubTitle}>
            Others
          </h6>
          <ul className="ml-2 mt-2">
            <a onClick={() => this.props.logout()}>
              <li>
                <FaSignOutAlt size={10} />{" "}
                <span style={{ marginLeft: ".5rem" }}>Logout</span>
              </li>
            </a>
          </ul>
        </div>
        <div style={sticky} className="mt-4 mb-4">
          <img
            style={{ alignContent: "flex-end" }}
            src={logo}
            alt="VOSCH Edu"
          />
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logoutUser()),
  };
};

export default connect(null, mapDispatchToProps)(SidebarContent);
