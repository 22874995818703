import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
//import { store } from "app/redux/storeConfig/store";
import configureStore from "app/store";
import { FallbackSpinner } from "@core/components";
import { Layout } from "./app/contexts";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-toastify/dist/ReactToastify.css";
import checkAuth from "@core/helpers/auth/checkAuth";

const LazyApp = lazy(() => import("./app"));

const store = configureStore();

checkAuth(store);

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<FallbackSpinner />}>
      <Layout>
        <LazyApp />
      </Layout>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
