import { createSlice } from "@reduxjs/toolkit";
import { themeConfig } from "app/configs";

const slice = createSlice({
  name: "customizer",
  initialState: themeConfig,
  reducers: {
    changeMode: (customizer, action) => {
      customizer.theme = action.payload;
    },
    collapseSidebar: (customizer, action) => {
      customizer.sidebarCollapsed = action.payload;
    },
    changeNavbarColor: (customizer, action) => {
      customizer.navbarColor = action.payload;
    },
    changeNavbarType: (customizer, action) => {
      customizer.navbarType = action.payload;
    },
    changeFooterType: (customizer, action) => {
      customizer.footerType = action.payload;
    },
    changeMenuColor: (customizer, action) => {
      customizer.menuTheme = action.payload;
    },
    hideScrollToTop: (customizer, action) => {
      customizer.hideScrollToTop = action.payload;
    },
  },
});

export const {
  changeMode,
  collapseSidebar,
  changeNavbarColor,
  changeNavbarType,
  changeFooterType,
  changeMenuColor,
  hideScrollToTop,
} = slice.actions;
export default slice.reducer;
