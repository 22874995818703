import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { cacheTimeout } from "app/configs/appConfig.json";
import { apiCallBegan } from "app/store";
import moment from "moment";
import { toast } from "react-toastify";

toast.configure();

const slice = createSlice({
  name: "posts",
  initialState: {
    list: [],
    loading: false,
    lastFetch: null,
    singlePost: {
      post: {},
      comments: [],
      isLoading: false,
      isError: false,
      fetched: false,
    },
    //lastFetch: Number(localStorage.getItem("lastFetch")),
  },
  reducers: {
    postAdded: (posts, action) => {
      //posts.list.push(action.payload);
      const decoded = JSON.parse(
        atob(localStorage.getItem("token").split(".")[1])
      );
      //console.log(decoded);
      let post = action.payload;
      //post.created_at = Date.now();
      let author = {
        profile_image: decoded.avatar,
        name: decoded.username,
        _id: decoded._id,
      };
      post.author = author;
      //post.author.name = decoded.username;
      posts.list.unshift(post);
      //toast.success("😋 Post Added");
    },
    postsReceived: (posts, action) => {
      //posts.list = action.payload.reverse();
      posts.list = action.payload;
      posts.loading = false;
      posts.lastFetch = Date.now();
      posts.singlePost.isError = false;
      //localStorage.setItem("lastFetch", Date.now());
      //posts.lastFetch = Number(localStorage.getItem("lastFetch"));
    },
    postsRequested: (posts, action) => {
      posts.loading = true;
    },
    postsRequestFailed: (posts, action) => {
      //posts.lastFetch = Date.now();
      posts.loading = false;
    },
    postDeleted: (posts, action) => {
      posts.list = posts.list.filter((post) => post._id !== action.payload);
      /*
      posts.list.filter(function (post, index) {
        if (post._id === action.payload)
          console.log(index + "to remove from state");
        if (post._id !== action.payload)
          console.log(index + "to keep in state");
      });
      //console.log(posts.list);
      */
    },
    singlePostReceived: (posts, action) => {
      //########### Si le post n'existe pas => ERROR ###########
      //console.log(action.payload);
      if (action.payload) {
        posts.singlePost.post = action.payload.post[0];
        posts.singlePost.comments = action.payload.comment || [];
        posts.singlePost.isLoading = false;
        posts.singlePost.isError = false;
        posts.singlePost.fetched = true;
        return;
      }
      posts.singlePost.isError = true;
    },
    singlePostRequested: (posts, action) => {
      posts.singlePost.isError = false;
      posts.singlePost.isLoading = true;
    },
    singlePostRequestFailed: (posts, action) => {
      posts.singlePost.isError = true;
    },
    postAddCommentRequested: (posts, action) => {
      const decoded = JSON.parse(
        atob(localStorage.getItem("token").split(".")[1])
      );
      //console.log(decoded);
      let post = action.payload;
      post._id = 1;
      post.created_at = Date.now();
      let author = {
        profile_image: decoded.avatar,
        name: decoded.username,
      };
      post.author = author;

      //console.log(action.payload);
      posts.singlePost.comments.push(post);
    },
    postCommentAdded: (posts, action) => {
      posts.singlePost.isError = false;
    },
    commentDeleted: (posts, action) => {
      posts.singlePost.comments = posts.singlePost.comments.filter(
        (comment) => comment._id !== action.payload
      );
    },
  },
});

const {
  postAdded,
  singlePostReceived,
  postsReceived,
  postsRequested,
  postsRequestFailed,
  postDeleted,
  singlePostRequested,
  singlePostRequestFailed,
  postCommentAdded,
  postAddCommentRequested,
  commentDeleted,
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "/school/wall";

export const addPost = (post) =>
  apiCallBegan({
    url: "/post",
    method: "post",
    data: post,
    onSuccess: postAdded.type,
  });

export const deletePost = (id) =>
  apiCallBegan({
    url: "/post/" + id,
    method: "delete",
    onSuccess: postDeleted.type,
  });

export const deleteComment = (id) =>
  apiCallBegan({
    url: "/comment/" + id,
    method: "delete",
    onSuccess: commentDeleted.type,
  });

export const loadPosts = () => (dispatch, getState) => {
  // Caching

  const { lastFetch } = getState().entities.posts;
  const diffInMinutes = moment().diff(moment(lastFetch), "minutes");

  if (diffInMinutes < cacheTimeout) return;

  dispatch(
    apiCallBegan({
      url,
      onStart: postsRequested.type,
      onSuccess: postsReceived.type,
      onError: postsRequestFailed.type,
    })
  );
};

export const loadPostById = (postId) =>
  apiCallBegan({
    url: "/post/" + postId + "/comment",
    onStart: singlePostRequested.type,
    onSuccess: singlePostReceived.type,
    onError: singlePostRequestFailed.type,
  });

/*
export const hidePost = (postId) =>
  apiCallBegan({
    url: "/post/" + postId,
    method: "patch",
    data: { hide: true },
    onSuccess: postHidded.type,
  });
  */

// Selectors
export const getPostById = (postId) =>
  createSelector(
    (state) => state.entities.posts,
    (posts) => posts.filter((post) => post._id === postId)
  );

export const addPostComment = (id, comment) =>
  apiCallBegan({
    url: "/post/" + id + "/comment",
    method: "post",
    data: comment,
    onStart: postAddCommentRequested.type,
    //onSuccess: postCommentAdded.type,
    //onError: postsRequestFailed.type,
  });

export const getSinglePost = (state) => state.entities.posts.singlePost;
