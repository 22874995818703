import axios from "axios";
import { createAction } from "@reduxjs/toolkit";
import { apiUrl } from "app/configs/appConfig.json";

// Action Creators
export const apiCallBegan = createAction("api/callBegan");
export const apiCallSuccess = createAction("api/callSuccess");
export const apiCallFeiled = createAction("api/callFeiled");

// API Middleware
const api = ({ dispatch /*, getState*/ }) => (next) => async (action) => {
  if (action.type !== apiCallBegan.type) return next(action);

  // Check Auth
  /*
  const AUTH_TOKEN = getState().auth.token;
  if (AUTH_TOKEN) axios.defaults.headers.common["x-token"] = AUTH_TOKEN;
  */

  const { url, method, data, onStart, onSuccess, onError } = action.payload;

  if (onStart) dispatch({ type: onStart, payload: data });

  next(action);

  try {
    const response = await axios.request({
      baseURL: apiUrl,
      url,
      method,
      data,
    });
    //console.log(response);
    // General Success
    dispatch(apiCallSuccess(response.data));
    // Specific
    if (onSuccess) dispatch({ type: onSuccess, payload: response.data });
  } catch (error) {
    // General Error
    dispatch(apiCallFeiled(error.message));
    // Specific
    if (onError) dispatch({ type: onError, payload: error.message });
  }
};

export default api;
