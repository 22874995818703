import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "loader",
  initialState: {
    isLoading: false,
    toast: {
      type: "",
      message: "",
    },
  },
  reducers: {
    toggleLoader: (loader, action) => {
      loader.isLoading = !loader.isLoading;
    },
    loadErrorToast: (loader, action) => {
      loader.isLoading = !loader.isLoading;
    },
  },
});

export const { toggleLoader } = slice.actions;
export default slice.reducer;
//loaderToggled
