import React from "react";
import { NavItem, NavLink } from "reactstrap";
//import * as Icon from "react-feather";
import Skeleton from "react-loading-skeleton";

class NavbarBookmarks extends React.PureComponent {
  render() {
    let { sidebarVisibility, userImg } = this.props;

    return (
      <div className="mr-auto float-right bookmark-wrapper d-flex align-items-center">
        <ul className="navbar-nav d-xl-none">
          <NavItem className="mobile-menu mr-auto">
            <NavLink
              className="nav-menu-main menu-toggle hidden-xs is-active"
              onClick={sidebarVisibility}
            >
              {/**
               * <Icon.Menu className="ficon" />
               */}
              <span data-tour="user">
                <img
                  src={
                    userImg || <Skeleton circle={true} height={30} width={30} />
                  }
                  className="round"
                  height="30"
                  width="30"
                />
              </span>
            </NavLink>
          </NavItem>
        </ul>
      </div>
    );
  }
}

export default NavbarBookmarks;
