import jwt_decode from "jwt-decode";
import setAuthToken from "./setAuthToken";
import { setCurrentUser } from "app/store/slices/auth";

const checkAuth = (store) => {
  try {
    // Check for token to keep user logged in
    let token = localStorage.getItem("token");
    if (token) {
      // Set auth token header auth
      setAuthToken(token);

      // Decode token and get user info and exp
      //const decoded = jwt_decode(token);
      //console.log(token);
      const decoded = JSON.parse(atob(token.split(".")[1]));
      //console.log(decoded);
      //console.log(decoded);

      // Set user and isAuthenticated
      store.dispatch(setCurrentUser(decoded));

      /*
    // Check for expired token
    const currentTime = Date.now() / 1000; // to get in milliseconds
    if (decoded.exp < currentTime) {
      // Logout user
      store.dispatch(logoutUser());
      // Redirect to login
      window.location.href = "./login";
    }
    */
    }
  } catch (e) {
    console.log(e);
  }
};

export default checkAuth;
